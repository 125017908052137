import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { Menu } from 'antd';
import TagPage from './TagPage';
import './App.css';

const tags: string[] = ['2024-09-14 to 2024-09-28', '2024-09-29 to 2024-10-13', '2024-10-14 to 2024-10-28'];

function App() {
    return (
        <Router>
            <div>
                <Menu mode="horizontal" theme="dark">
                    {tags.map((tag) => (
                        <Menu.Item key={tag}>
                            <Link to={`/tags/${tag.toLowerCase()}`}>{tag}</Link>
                        </Menu.Item>
                    ))}
                </Menu>

                <div className="content">
                    <Routes>
                        <Route path="/tags/:tag" element={<TagPage />} />
                        <Route path="/" element={<h1>Welcome to GitLab Label Viewer</h1>} />
                    </Routes>
                </div>
            </div>
        </Router>
    );
}

export default App;
