import React from 'react';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
} from 'recharts';
import { Card } from 'antd';

interface BurndownChartProps {
    totalEstimate: number;
    totalTimeSpent: number;
}

interface ChartDataPoint {
    day: string;
    remaining: number;
}

function BurndownChart({ totalEstimate, totalTimeSpent }: BurndownChartProps) {
    // Placeholder data for demonstration
    const data: ChartDataPoint[] = [
        { day: 'Day 1', remaining: totalEstimate },
        { day: 'Day 2', remaining: totalEstimate - totalTimeSpent * 0.25 },
        { day: 'Day 3', remaining: totalEstimate - totalTimeSpent * 0.5 },
        { day: 'Day 4', remaining: totalEstimate - totalTimeSpent * 0.75 },
        { day: 'Day 5', remaining: totalEstimate - totalTimeSpent },
    ];

    return (
        <ResponsiveContainer width="100%" height={300}>
            <LineChart
                data={data}
                margin={{
                    top: 5,
                    right: 20,
                    left: 10,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="day" />
                <YAxis
                    label={{
                        value: 'Remaining Time (hours)',
                        angle: -90,
                        position: 'insideLeft',
                    }}
                    tickFormatter={(value) => (value / 3600).toFixed(1)}
                />
                <Tooltip
                    formatter={(value: number) => `${(value / 3600).toFixed(2)} hours`}
                />
                <Line type="monotone" dataKey="remaining" stroke="#8884d8" />
            </LineChart>
        </ResponsiveContainer>
    );
}

export default BurndownChart;
