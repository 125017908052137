import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { List, Avatar, Progress, Typography, Card, Row, Col, Space } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import BurndownChart from './BurndownChart';

const { Title, Text } = Typography;

interface Assignee {
    name: string;
    avatar_url: string;
}

interface Task {
    id: number;
    title: string;
    assignee: Assignee | null;
    timeEstimate: number;
    totalTimeSpent: number;
}

interface PersonStats {
    name: string;
    avatarUrl: string | null;
    totalEstimate: number;
    totalTimeSpent: number;
}

function TagPage() {
    const [tasks, setTasks] = useState<Task[]>([]);
    const [peopleStats, setPeopleStats] = useState<Record<string, PersonStats>>({});
    const [totalEstimate, setTotalEstimate] = useState<number>(0);
    const [totalTimeSpent, setTotalTimeSpent] = useState<number>(0);

    const { tag } = useParams<{ tag: string }>();

    useEffect(() => {
        axios
            .get<Task[]>(`/api/gitlab/tasks`, {
                params: { label: tag },
            })
            .then((response) => {
                setTasks(response.data);
                processData(response.data);
            })
            .catch((error) => {
                console.error('Error fetching tasks', error);
            });
    }, [tag]);

    const processData = (tasks: Task[]) => {
        const stats: Record<string, PersonStats> = {};
        let estimateSum = 0;
        let timeSpentSum = 0;

        tasks.forEach((task) => {
            const assigneeName = task.assignee ? task.assignee.name : 'Unassigned';
            const avatarUrl = task.assignee ? task.assignee.avatar_url : null;

            if (!stats[assigneeName]) {
                stats[assigneeName] = {
                    name: assigneeName,
                    avatarUrl: avatarUrl,
                    totalEstimate: 0,
                    totalTimeSpent: 0,
                };
            }

            stats[assigneeName].totalEstimate += task.timeEstimate;
            stats[assigneeName].totalTimeSpent += task.totalTimeSpent;

            estimateSum += task.timeEstimate;
            timeSpentSum += task.totalTimeSpent;
        });

        setPeopleStats(stats);
        setTotalEstimate(estimateSum);
        setTotalTimeSpent(timeSpentSum);
    };

    const formatTime = (seconds: number): string => {
        const hours = (seconds / 3600).toFixed(2);
        return `${hours} hours`;
    };

    const totalProgress =
        totalEstimate > 0 ? (totalTimeSpent / totalEstimate) * 100 : 0;

    return (
        <div style={{ padding: '20px' }}>
            <Title level={2}>Tag: {tag}</Title>
            <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                <Card>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Text strong>Total Estimate:</Text> {formatTime(totalEstimate)}
                        </Col>
                        <Col span={8}>
                            <Text strong>Total Time Spent:</Text> {formatTime(totalTimeSpent)}
                        </Col>
                        <Col span={8}>
                            <Text strong>Progress:</Text> {totalProgress.toFixed(2)}%
                        </Col>
                    </Row>
                    <Progress percent={totalProgress} />
                </Card>

                <Title level={3}>People Working on Tasks:</Title>
                <List
                    itemLayout="horizontal"
                    dataSource={Object.values(peopleStats)}
                    renderItem={(person) => {
                        const personProgress =
                            person.totalEstimate > 0
                                ? (person.totalTimeSpent / person.totalEstimate) * 100
                                : 0;
                        return (
                            <List.Item>
                                <List.Item.Meta
                                    avatar={
                                        person.avatarUrl ? (
                                            <Avatar src={person.avatarUrl} />
                                        ) : (
                                            <Avatar icon={<UserOutlined />} />
                                        )
                                    }
                                    title={person.name}
                                    description={
                                        <>
                                            <Text>Total Estimate: {formatTime(person.totalEstimate)}</Text>
                                            <br />
                                            <Text>
                                                Total Time Spent: {formatTime(person.totalTimeSpent)}
                                            </Text>
                                            <br />
                                            <Text>Progress: {personProgress.toFixed(2)}%</Text>
                                            <Progress percent={personProgress} />
                                        </>
                                    }
                                />
                            </List.Item>
                        );
                    }}
                />

                <Title level={3}>Burndown Chart:</Title>
                <Card>
                    <BurndownChart
                        totalEstimate={totalEstimate}
                        totalTimeSpent={totalTimeSpent}
                    />
                </Card>
            </Space>
        </div>
    );
}

export default TagPage;
